<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="Configuracoes/Importacao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getImportacao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getImportacao();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Arquivo",
          sortable: true,
          showArquivo:true,
          value: "arquivo",
        },
        {
          text: "Tipo",
          sortable: true,
          value: "strTipoArquivo",
        },
        // {
        //   text: "Total de Linhas",
        //   sortable: true,
        //   value: "intTotalRegistro",
        // },
        // {
        //   text: "Total de Linhas Atualizadas",
        //   sortable: true,
        //   value: "intTotalRegistroInserido",
        // },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
        // {
        //   text: "Log",
        //   sortable: true,
        //   collapse: 1,
        //   collapseLabel:'Visualizar logs',
        //   value: "strMensagem",
        // },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "configuracoes.importacao.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "configuracoes.importacao.edit",
        params: { intId: data.intId },
      });
    },

    getImportacao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "Configuracoes/Importacao",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objContaEmail) => {

              let strStatus = [];

              strStatus.push('Registros processados: '+objContaEmail.intTotalRegistroProcessado+' de '+objContaEmail.intTotalRegistro)
              strStatus.push('Registros importados: '+objContaEmail.intTotalRegistroInserido+' de '+objContaEmail.intTotalRegistro)
              let item = {
                intId: objContaEmail.intId,
                arquivo: objContaEmail.arquivo.strNome,
                strMensagem:objContaEmail.strMensagem,
                strTipoArquivo:  objContaEmail.tipo_importacao.strNome,
                intTotalRegistro: Number(objContaEmail.intTotalRegistro),
                intTotalRegistroInserido: Number(objContaEmail.intTotalRegistroInserido),
                strStatus:  '<span style="color:'+objContaEmail.tipo_status.strValor.strCor+'">'+objContaEmail.tipo_status.strNome+'<br>'+strStatus.join('<br>')+'</span>',
                _item: objContaEmail,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
